<template>
	<main class="main-page">
		<template v-if="showHeader">
			<section class="page-section mb-3">
				<div class="container">
					<div class="grid align-items-center">
						<div v-if="!isSubPage" class="col-fixed ">
							<Button @click="$router.go(-1)" label="" className="p-button p-button-text "
								icon="pi pi-arrow-left" />
						</div>
						<div class="col ">
							<div class="">
								<div class="flex align-items-center ">
									<div>
										<div class="text-2xl text-primary font-bold">Term of Service</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
		<section class="page-section ">
			<div class="container">
				<div class="grid ">
					<div class="md:col-9 sm:col-12 comp-grid">
						<div>
							<template v-if="pageReady">
								<form ref="observer" tag="form" @submit.prevent="submitForm()"
									:class="{ 'card': !isSubPage }" class="">
									<!--[form-content-start]-->
									<div class="grid">
										<div class="col-12">
											<div v-html="formData.terms_of_use"></div>
										</div>
									</div>
									<!--[form-content-end]-->
									<div v-if="showSubmitButton" class="text-center mt-4">
										<Button type="submit" label="Accept Terms of Service" :loading="saving" />
									</div>
								</form>
							</template>
							<template v-if="loading">
								<div class="p-3 text-center">
									<ProgressSpinner style="width:50px;height:50px" />
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script setup>
import { computed, reactive, toRefs, onMounted } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@/services/validators';
import { useApp } from '@/composables/app.js';
import { useEditPage } from '@/composables/editpage.js';
const props = defineProps({
	id: [String, Number],
	pageName: {
		type: String,
		default: 'info',
	},
	routeName: {
		type: String,
		default: 'infoterms',
	},
	pagePath: {
		type: String,
		default: 'info/terms',
	},
	apiPath: {
		type: String,
		default: 'info/terms',
	},
	submitButtonLabel: {
		type: String,
		default: "Update",
	},
	formValidationError: {
		type: String,
		default: "Form is invalid",
	},
	formValidationMsg: {
		type: String,
		default: "Please complete the form",
	},
	msgTitle: {
		type: String,
		default: 'Terms of Use',
	},
	msgBeforeSave: {
		type: String,
		default: "",
	},
	msgAfterSave: {
		type: String,
		default: "Terms of Use Accepted",
	},
	showHeader: {
		type: Boolean,
		default: true,
	},
	showSubmitButton: {
		type: Boolean,
		default: true,
	},
	redirect: {
		type: Boolean,
		default: true,
	},
	isSubPage: {
		type: Boolean,
		default: false,
	},
	pageData: { // use to set formData default values from another page
		type: Object,
		default: () => { }
	},
});
const app = useApp();
const formDefaultValues = Object.assign({}, props.pageData);
const formData = reactive({ ...formDefaultValues });
function afterSubmit(response) {
	app.flashMsg(props.msgTitle, props.msgAfterSave);
	if (app.isDialogOpen()) {
		app.closeDialogs(); // if page is open as dialog, close dialog
	}
	app.openPageDialog({ page: 'transactions/add', url: `/transactions/add`, persistent: true, closeBtn: true, pageData: formData })
}
const rules = computed(() => {
	return {
	}
});
const v$ = useVuelidate(rules, formData); //form validation
const page = useEditPage({ props, formData, v$, afterSubmit });
const { submitted, saving, loading, pageReady } = toRefs(page.state);
const { apiUrl, currentRecord } = page.computedProps;
const { load, submitForm, getErrorClass, getFieldError, isFieldValid, mapOptionField } = page.methods;
onMounted(() => {
	const pageTitle = "Terms of Use";
	app.setPageTitle(props.routeName, pageTitle); // set browser page title
	load();
});
</script>
<style scoped></style>